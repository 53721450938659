.importingSpinArea
  text-align center
  background rgba(0,0,0,0.05)
  border-radius 4px
  margin-bottom 20px
  padding 30px 50px
  margin 20px 0

.alertStyl
  margin 5px 0 5px 0

.alertStylForBundleInfo
  margin 20px 0 10px 0
  font-size 16px
  color green