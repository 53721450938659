#components-layout-demo-top-side-2 .logo
    width 120px
    height 31px
    background #333
    border-radius 6px
    margin 16px 28px 16px 0
    float left

.reportCard
    padding 50px
    color white
    
.textAlign
     text-align center
     background black 

.textStyle
     color #a6a6a6
     font-size 30px

.valueStyle
      color white
      font-size 70px
      font-weight 500
      margin 0px 28px 0px 0

.rowMargin
      margin 50px

.rowBorder
      border-top 2px solid #40cabf
      width 1800px
      margin 0 auto

.colBorder
      border-right 2px solid #40cabf

.ant-picker-large .ant-picker-input > Input
      color white
      text-Align center
      font-size 28px

.ant-picker-separator
      color white
