#components-layout-demo-top-side-2 .logo
    width 120px
    height 31px
    background #333
    border-radius 6px
    margin 16px 28px 16px 0
    float left

.notStartedWO
    color #69FFC3 

.materialRequisitionWO
    color #69FFF0 

.trimmingWO
    color #09BDD9

.sawingWO
    color #1890ff
    
.fillingWO
    color #b37feb

.polishingWO
    color #52c41a

.cancelledWO
    color #fa541c

.manufacturingWO
    color #5200A3

.largeFont
    font-size 30px
    color white

.evenRow
    background #000

.oddRow
    background #262626

.outlineDiv
    padding 20px
    background black

.attention
    color #ff0

.warning
    color #ff4d4f

.outlineDiv .ant-table-tbody > tr > td
    border none

.outlineDiv .ant-table-thead > tr > th
    background black
    color white

.realtimeReportBackground
    background black
    height 1080px
    width 1920px

.outlineDiv .ant-table-tbody > tr:hover > td
    background #595959
