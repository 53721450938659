.iconStyle
    font-size 40px
    height 45px
    border none
    color white
    background none
    visibility hidden
    &:hover
      background none

.controlArea
    position fixed
    text-Align center
    left 0
    right 0
    bottom 10px
    z-index 100
    &:hover .iconStyle
      visibility visible

.buttonField
    margin 0 auto
