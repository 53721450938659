body
    font-size 14px
    color #333
    background-color #fff

.paginationArea
    margin-top 10px
    text-align right

.deleteTileInfoPage
    color: red
    font-size: 16px
    margin-bottom:12px

.deleteTile
    margin-left: 15px

.exportFormStyl {
  border: 1px solid #d9d9d9
  border-radius: 2px
}

.iconPaid
    color: #00CC00
    font-size: 24px

.iconLoaded
    color: #1296db
    font-size: 24px

.iconShipped
    color: #00CC00
    font-size: 24px

.iconAddedToPO
    color: #1296db
    font-size: 20px

.sysInfoTitle
     font-size: 16px 

.skipFillingInfo
  font-size: 16px
  color: #1068db

.woMSStyle
  font-size: 20px
  color: #1068db

.blockDiscardedStyle
  font-size: 20px
  color: red

.referencePriceColor
    color #097BF7

.ant-btn:first-child {
    margin-left: 0 !important;
}

.ant-btn:last-child {
    margin-right: 0 !important;
}

.ant-btn
    margin-right: 8px